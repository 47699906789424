import styled from 'styled-components';
import BgImg from '/public/images/NewDashboardV2/weeklyMotivation.png';
import BgImgDailyTip from '/public/images/NewDashboardV2/weeklyMotivation.png';
// import BgImgDailyQuote from '/images/NewDashboardV2/blueBackground.png';
const MainContainer = styled.div`
float: left;
margin-bottom: 0px;
width: 100%;
// margin-right: 15px;
// height: 100%;
// margin-top: 22px;
// padding: 25px 5px 0px 25px;
padding: 25px 0px 0px 0px;
max-height: 405px;
min-height: auto;
min-width: 318px;
@media(max-width: 1200px){
  min-width: 305px
}
@media(max-width: 1100px){
  min-width: 285px;
}

  > div:first-child {
    text-transform: Captialize;
    font-family: 'Rubik-Medium';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #0D4270;
  }
  @media(max-width: 1200px) {
    width: 100%;
    // height: 100%;
    margin-right: 0px;
    padding: 25px 10px 0px 10px;
  }
  @media (max-width: 766px) {
    width: 100%;
  }
`;
const SubContainerDailyTip = styled.div`
width: 100%;
padding: 24px 35px 12px 13px;
padding: 15px 25px 5px 15px
margin-top: 15px;
border-radius: 4px;
// background: url(${({bgImgStatus}) => bgImgStatus? BgImgDailyTip : BgImg});
// background-size: 100% 100%;
// background-repeat: no-repeat;
background-color: rgb(105, 194, 255);
color: #FFFFFF;
font-size: 17px;
line-height: 20px;
font-family: 'Rubik-Regular';
display: flex;
justify-content: space-around;
flex-flow: column;
font-family: Rubik;
font-style: normal;
font-weight: normal;
font-size: 16px;
line-height: 20px;
color: #FD7175;
background: rgba(255, 191, 193, 0.2);
border-radius: 6px;
${'' /* height: 150px; */}

> div:first-child {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: normal;
  line-height: 20px;
  color: #FFFFFF;
  width: 100%;
  text-align: start;
  margin-bottom: 10px;
  font-family: Rubik;
font-style: normal;
font-weight: normal;
font-size: 16px;
line-height: 20px;
color: #FD7175;
@media (max-width: 1100px){
  font-size: 14px;
}
}

> div: last-child {
  width: 100%;
}
`;
const SubContainerQuote = styled.div`
width: 100%;
padding:15px 25px 25px 15px;
margin-top: 15px;
border-radius: 4px;
// background-image: url{'/images/NewHomePageV2/blueBackground.png'};
// background-image: url('/images/HomePageV2/Home_banner.png');
background-size: 100% 100%;
background-repeat: no-repeat;
background-color: rgb(105, 194, 255);
color: #69C2FF;
font-size: 17px;
line-height: 20px;
font-family: 'Rubik-Regular';
display: flex;
justify-content: space-around;
flex-flow: column;
font-family: Rubik;
font-style: normal;
font-weight: normal;
font-size: 16px;
line-height: 20px;
font-family: Rubik;
font-style: normal;
font-weight: normal;
font-size: 16px;
line-height: 20px;
background-color:rgb(173, 222, 255, .2);
color: rgba(105, 194, 255, 1);
${'' /* height: 150px; */}

> div:first-child {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 20px;
  width: 100%;
  text-align: start;
  margin-bottom: 10px;font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  @media (max-width: 1100px){
    font-size: 14px;
  }
}

> div: last-child {
  width: 100%;
}
`;

export {
  MainContainer, SubContainerDailyTip, SubContainerQuote
};
