import React from 'react';
import PropTypes from 'prop-types';
import { MainContainer, SubContainerDailyTip, SubContainerQuote } from './styles';
import { getMotivationalQuote } from '../../../../redux/actions';
import { isEmpty, isNull, isUndefined } from 'lodash';
import { connect } from 'react-redux';
// import {LoaderContainer} from "../../../Content/styles";
// import Loading from '../../../Loading';
import { Interweave } from 'interweave';
import { withTranslation } from 'react-i18next';
import SkeletonLoder from '../../../common/skeletonLoder';

class WeeklyMotivationV3 extends React.Component {

  componentDidMount() {
    const { getMotivationalQuote } = this.props;
    getMotivationalQuote();
  }
  render() {
    const { motivationalQuote, dailyTip, dailyTipStatus, t } = this.props;
    return(
      <MainContainer>
        <div>
          {dailyTipStatus ?  t("Daily Tips") : t("Weekly Motivation")}
        </div>
        {dailyTipStatus ? 
          <SubContainerDailyTip bgImgStatus={dailyTipStatus ? 1 : 0} >
            <div>
              <p><Interweave content={this.props.t(dailyTip.daily_tip)} /></p>
            </div>
          </SubContainerDailyTip>:
          isEmpty(motivationalQuote) || isNull(motivationalQuote) || isUndefined(motivationalQuote)
            ?
            <SkeletonLoder width={"431px"} height={"275px"}/>
            :
            <SubContainerQuote >
              <div>
                <p>{this.props.t(motivationalQuote.quote)}</p>
              </div>
              <div>
                {`- ${motivationalQuote.author}`}
              </div>
            </SubContainerQuote>
        }
      </MainContainer>
    );
  }
}
WeeklyMotivationV3.propTypes = {
  motivationalQuote: PropTypes.object,
  dailyTip: PropTypes.object,
  dailyTipStatus: PropTypes.number,
  getMotivationalQuote: PropTypes.func.isRequired,
  t: PropTypes.func
};
const mapStateToProps = (state) => ({
  motivationalQuote: state.profileData.motivationalQuote,
})
const mapDispatchToProps = (dispatch) => ({
  getMotivationalQuote: () => dispatch(getMotivationalQuote()),
})
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(WeeklyMotivationV3));