import styled from 'styled-components';

const GraphMain = styled.div`
  position: relative;
  width: calc(100%/${({count}) => count ? count : 3});

  > div:first-child {
    width: 100%;
    position: relative;
  }

`;
const HighChartStyled = styled.div`
  width: 100%;
  margin: 0 auto;
`;
const ContainerBackground = styled.div`
width: ${({width}) => width ? width : '100%'};
top: ${({check}) => check ? '32%' : '29%' };
position: absolute;

.imageContainer {
  height: 22px;
  width: 17.25px;
  }

@media(min-width:1600px){
  top: ${({check}) => check ? '32%' : '32%' };
}
@media(min-width:1800px){
  top: ${({check}) => check ? '32%' : '32%' };
}
@media(max-width: 1240px) {
  top: 39%;
}
@media(max-width: 1300px) and (min-width: 1246px) {
  top: ${({check}) => check ? '28%' : '24%' };
}
`;
const ContainerInsideChart = styled.div`
    position: absolute;
    top: 195%;
    margin-left: ${({marginLeft}) => marginLeft ? '0px' : '3px'};
    font-size: 18px;
    font-family: Rubik-Regular;
    color: #fff;
    letter-spacing: ${(props) => props.calories > 0 ? '2px': '0px'};
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;

    > p {
      margin: 0px;
      font-size: 18px;
      font-family: Rubik-Regular;
      color: #A3B1C6;
      letter-spacing: normal;
    }
    @media (max-width: 1200px) and (min-width: 1000px) {
      font-size: 16px;
      > p {
        font-size: 16px;
      }
    }
    @media (max-width: 950px) {
      font-size: 17px;
      > p {
      font-size: 17px
      }
    }
    @media (max-width: 810px) {
      font-size: 15px;
      > p {
      font-size: 15px
      }
    }
    @media(max-width: 766px) {
      top: 205%;
    }
    @media(max-width: 600px) {
      margin-left: 0px;
    }
    @media (max-width: 400px) {
      font-size: 13px;
      > p {
      font-size: 13px
      }
    }
`;
export { GraphMain, HighChartStyled, ContainerBackground, ContainerInsideChart };