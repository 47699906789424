import React, { Component } from 'react';
import Highcharts from "highcharts";
import PropTypes from 'prop-types';
import { GraphMain, HighChartStyled, ContainerBackground, ContainerInsideChart } from './styles';
// eslint-disable-next-line no-undef
require('highcharts/highcharts-more')(Highcharts);
// eslint-disable-next-line no-undef
require('highcharts/modules/solid-gauge')(Highcharts);

class SmallHighCharts extends Component {

  componentDidMount() {
    const { payload, id } = this.props;
    new Highcharts.chart({
      chart: {
        type: 'solidgauge',
        renderTo: id,
        height: '100%',
        backgroundColor: 'rgba(255, 255, 255, 0.0)'
      },

      title: null,

      credits: {
        enabled: false
      },

      tooltip: {
        enabled: false
      },

      pane: {
        startAngle: 90,
        endAngle: -270,
        size: '70%',
        background: [{
          outerRadius: '70%',
          innerRadius: '60%',
          backgroundColor: '#1E6481' || Highcharts.Color(Highcharts.getOptions().colors[0])
            .setOpacity(0.3)
            .get(),
          borderWidth: 0,
          shape: "arc"
        }],
      },

      yAxis: {
        min: 0,
        max: 100,
        lineWidth: 0,
        tickPositions: []
      },

      plotOptions: {
        solidgauge: {
          dataLabels: {
            enabled: false
          },
          linecap: 'round',
          stickyTracking: false,
          rounded: parseFloat(id === "calories" ? payload.calories : id === "location" ? payload.percent_distance : id === "time" ? '50' : null) > 0
        }
      },

      series: [{
        name: 'Move',
        data: [{
          color: id === "calories" ? '#ff7e19' : id === "location" ? '#94bef2' : id === "time" ? '#e01f77' : '#FFF',
          size: '70%',
          radius: '62%',
          innerRadius: '55%',
          y: parseFloat(id === "calories" ? payload.calories : id === "location" ? payload.percent_distance : id === "time" ? '50' : null)
        }],
      }]
    });
  }

  render() {
    const { id, payload, highChartID, check} = this.props;

    return (
      <GraphMain>
        <HighChartStyled id={highChartID} />
        <ContainerBackground top={id === "time" ? 1 : 0} check={check}>
          {id === "calories" ? <img src="/public/images/NewDashboardV2/calories.png" className="imageContainer"/> :
            id === "location" ? <img src="/public/images/NewDashboardV2/location.png" className="imageContainer"/> :
              id === "time" ? <img src="/public/images/NewDashboardV2/timeImg.png" className="imageContainer"/> : null
          }
          <ContainerInsideChart calories={parseFloat(payload && payload.calories)} marginLeft={id === "time" ? 1 : 0}>
            {payload ? parseFloat(id === "calories" ? payload.calories : id === "location" ? payload.percent_distance : id === "time" ? '50' : null) : null}
            <p>{id === "calories" ? 'KCal' : id === "location" ? 'Km' : id === "time" ? 'min' : null}</p>
          </ContainerInsideChart>
        </ContainerBackground>
      </GraphMain>
    )
  }

}

SmallHighCharts.propTypes = {
  id: PropTypes.string,
  payload: PropTypes.object,
  highChartID: PropTypes.string,
  check: PropTypes.bool
};

export default SmallHighCharts;
